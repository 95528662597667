$orange: #cd5a39;
$aqua: #429997;

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.clickable {
  cursor: pointer;
}

body {
  overscroll-behavior-y: none;
  font-display: swap;
  font-family: "Noto Sans", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  min-height: 100vh;
}

// TODO uncomment for redesign
.RedesignHome {
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: "Noto Sans", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    color: #417372 !important;
  }

  p {
    font-family: "Noto Sans", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
  }

  &.AboutUs {
    & > .ui.container {
      padding-top: 250px;
    }
    #splashToo {
      z-index: -1;
    }
    #pageContent {
      margin-top: 0;
    }

    section {
      margin: 50px 0;
    }
  }
}
p {
  color: #030303 !important;
}

// .figtree-thin {
//   font-weight: <weight>;
// }

.SponsorPrintOnly {
  display: none !important;
}
.ui.orange.button,
.ui.orange.buttons .button {
  background-color: $orange;
}
.ui.link.menu .item {
  color: white;
}
.ui.link.menu .item:hover,
.ui.menu .dropdown.item:hover,
.ui.menu .link.item:hover,
.ui.menu a.item:hover,
.ui.link.menu .item:active,
.ui.menu .link.item:active,
.ui.menu a.item:active {
  color: white;
  font-weight: bold;
}

.ui.corner.label.aqua {
  border-color: $aqua;
}

.ui {
  &.menu .item {
    color: white;
  }
  &.text.container {
    max-width: 900px !important;
  }
  &.sponsorLogos {
    margin: 25px 0;
    max-width: 90vw !important;
    & > .images {
      justify-content: space-evenly;
      display: flex;
      align-items: center;
      .image,
      & > img,
      & > svg {
        margin: 10px;
        filter: grayscale(100%);
        transition: filter 0.5s;
        cursor: pointer;

        &:hover {
          filter: none;
        }
      }
    }
  }

  &.sponsorLogos.fullColor > .images {
    & > img,
    & > svg {
      filter: none;
    }
  }
}

.ui.link.list .item,
.ui.link.list .item a:not(.ui),
.ui.link.list a.item {
  margin-bottom: 10px;
  padding-left: 10px;
}
.ui.header {
  padding-left: 10px;
}

.Boxes {
  display: flex;
  justify-content: space-evenly;
  align-items: stretch;
  text-align: left;
  position: relative;
  flex-wrap: wrap;

  &.alt-margin > div {
    margin: 0;
    margin: -10px 10px 10px -10px;

    & > div {
      margin: 0;
    }
  }

  img.more {
    position: absolute;
    right: -20px;
    bottom: 15px;
  }
}

.PageBox {
  img.more {
    position: absolute;
    right: -20px;
    bottom: 15px;
  }
}
#splash {
  background-repeat: no-repeat;
  background-size: cover;
  align-self: center;
  background-position: center -100px;
  height: 300px;
  margin-top: -10px;
}

#splashToo {
  position: absolute;
  z-index: 100;
  top: 0;
  height: 250px;
  width: 100vw !important;
  // margin-left: -2em !important;
  background-color: $aqua;
  background-image: url(../images/hero/abstract-bg.webp);
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: 62%;

  button {
    z-index: 999;
    position: relative;
  }
}

#pageContent {
  margin-top: 0px;
  z-index: 500;
  padding-top: 250px !important;
}

.HomeLayout {
  .ui.center.aligned.sponsorLogos {
    // padding-top: 250px !important;
  }
  .book {
    padding: 0px;
  }
  .LeftColumn {
    .HomeMainItem {
      .ui.top.attached.label:first-child + :not(.attached) {
        margin-top: 3rem;
      }

      margin: 2rem 0;
      p {
        color: #666 !important;
        font-size: 1.2rem;
        // width: 90ch;
        // max-width: 100%;
        // text-align: justify !important;
        // hyphens: auto;
        // font-size: 1em;
        line-height: calc(1ex / 0.32);
        margin: calc(1ex / 0.32) 0;
      }

      li {
        font-size: 1.2rem;
        line-height: calc(1ex / 0.32);
      }

      .PostDate {
        color: #999 !important;
        margin: 0 !important;
        padding: 0;
      }

      .PostBody * {
        margin-top: 0 !important;
        line-height: 2.1rem !important;
      }

      .PostBody ul {
        list-style-position: inside;
      }

      .PostHeader {
        margin-bottom: 0rem;
        color: #405958 !important;
      }

      .PostSubheader {
        font-size: 2.7em;
        font-family: "Kaushan Script", cursive;
        // line-height: calc(1ex / 0.42);
        // margin-top: calc(1ex / 0.42) 0;
      }

      .se-component.se-image-container.__se__float-left {
        float: left;
        max-width: 30%;

        // target next sibling of left-floated images and float right
        & + ul {
          margin-left: 0px;
          padding-left: 0px;
          float: right;
          width: 65%;

          // clear the float
          & + p {
            clear: both;
          }
        }
      }
    }
    .HomeMainItem:first-child {
      margin: 0;
    }
    .VideoPlaceholder {
      border-radius: 10px;
    }
  }

  .RightColumn {
    .ui.label.teal {
      background-image: linear-gradient(
        to right,
        #4cb8c4 0%,
        #3cd3ad 51%,
        #4cb8c4 100%
      ) !important;
      background-image: linear-gradient(
        to right,
        #4cb8c4 0%,
        #3cd3ad 90%,
        #4cb8c4 100%
      ) !important;
    }

    .ui.segment {
      margin-bottom: 3em;
      transition: all 0.3s ease-out;
      &.clickable {
        transform-style: flat;
        transform: none;

        &:hover {
          cursor: pointer;
          transform: scale(1.03);
        }
      }
    }

    p {
      color: #666 !important;
      font-size: 1rem;
    }
  }
}

@media (min-width: 453px) and (max-width: 1024px) {
  div.ui.tiny.images {
    flex-wrap: wrap;
  }
}

.ui.header {
  padding-left: 0;
  margin-bottom: 10px;
}
.ui.orange.header {
  color: $orange !important;
}

.ui.vertical.segment.footer {
  background-color: rgb(204, 204, 204);
  color: black;
  margin: 1em 0em 0em;
  padding: 17em 0em 2rem;
}

.FAQ {
  #FAQContent {
    margin-bottom: 40px;
  }

  blockquote {
    border-left: 4px solid #ccc;
    padding-left: 30px;
    margin-left: 10px;

    p {
      font-size: 1.2rem;
    }
  }
}

#pageContent {
  // margin-top: 100px;
}

.Sponsors h1 {
  margin: 3rem 0 0 0;
}
.sponsorImage {
  max-height: 200px;
}
.imgColumn {
  justify-self: center;
  align-self: center;
}

.Splash,
.Index {
  #pageContent {
    margin-top: 48px;
  }
}

.Splash {
  #splash {
    height: 200px;
    margin-bottom: 40px;
  }
  p {
    font-size: 1.1em;
    line-height: 1.8em;
  }
}

.books {
  display: flex;
  flex-wrap: wrap;
}

.book {
  display: flex;
  flex-direction: column;
  flex-basis: 30%;
  justify-content: flex-start;
  align-items: center;
  margin: 1em;
  padding-top: 2em;
  img {
    max-height: 230px;
    min-height: 230px;
    max-width: 165px;
    min-width: 165px;
  }

  h4 {
    min-height: 3em;
    margin-top: 0;
  }
}

.ui.menu .ui.dropdown.item .menu {
  .item,
  .item > a {
    color: rgba(0, 0, 0, 0.87) !important;
  }
}

.flex {
  display: flex !important;

  .centered {
    justify-self: center;
    align-self: center;
  }
}

.eventTitle {
  color: white !important;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  text-transform: uppercase;
}

.HomeLayout {
  .eventTitle a,
  .dateRange {
    color: #030303;
  }
}

// .pushable > .pusher {
//   min-height: 100vh;
// }

.footer.exp {
  position: static !important;
  bottom: 0 !important;
  width: 100vw !important;
  background-color: $aqua !important;
  background-image: url(../images/hero/abstract-footer-bg.webp) !important;
  background-repeat: no-repeat !important;
  // background-position: center !important;
  background-position: 0px -300px !important;

  .ui.link.list a.item {
    color: rgba(0, 0, 0, 0.7);
    // margin-top: 450px;
  }

  .footerLogo {
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 10px;
    margin-bottom: -10px;
  }

  #footerAddress h4 {
    font-weight: 400;
    color: #000 !important;
  }

  #copyrightContainer {
    color: rgba(0, 0, 0, 0.7);
    margin-top: 4em;
    margin-bottom: 7em;
    visibility: hidden;
  }
}

.MenuUsername {
  padding-left: 5px;
}

.dateRange {
  color: #efefef;
  font-size: 1rem;
  margin-bottom: 5px !important;
}

#regional-representatives {
  margin-top: 2em;
}

.ui.sticky {
  z-index: 0;
}

.DashboardLayout {
  #adminSidebar {
    padding-top: 30px;
    min-width: 175px;
    margin-top: 55px !important;
    z-index: 1;

    .ui.menu .item {
      color: black;
    }
  }

  .DashboardSidebar {
    margin: 0 !important;
    margin-top: 80px !important;
  }
}

.ui.grid {
  max-width: 100% !important;
}

.ui.compact.horizontal.segments {
  display: inline-flex;
}

ol {
  line-height: 1.8;
  list-style: upper-alpha;
  ol {
    list-style: decimal;
    ol {
      list-style: lower-alpha;
      ol {
        list-style: decimal;
      }
    }
  }
}

.BecomeASponsor {
  table {
    font-size: 1.2em !important;
  }

  strong {
    color: $aqua;
  }
  td:first-child {
    width: 40em;
  }
  td:not(:first-child) {
    width: 12em;
    background-color: $aqua;
    color: white;
    border: 1em;
    text-align: center;
  }
}

.Announcements {
  .ui.items > .item > .content > .header {
    color: teal !important;
  }
}

.About {
  p {
    font-size: 1.2em;
  }
}

.Mission {
  p {
    font-size: 1.2em;
  }
}

.Certification {
  li {
    font-size: 1.2rem;
    line-height: 1.8rem;
  }
}

.Recertification {
  .content {
    font-size: 1.1rem;
    line-height: 2.1rem;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.RecertificationPolicy {
  p {
    font-size: 1.1rem;
    line-height: 1.8rem;
  }
}

.Public {
  p {
    font-size: 1.1rem;
    line-height: 1.8rem;
  }
  .Box_Box__VpISF > div.Box_withImage__3Fs0t {
    background-position: center;
  }
}

.TrainingPrograms {
  &.content {
    color: $aqua;
  }
}

.ResourceLinks {
  &.Segment {
    color: $aqua;
  }
}

.ui.breadcrumb {
  .section:not(:last-child) {
    color: $aqua;
  }
}

.Leadership {
  .ui.items > .item:last-child {
    margin-bottom: 14px;
  }
  .boardImage.ui.image img {
    height: 130px;
    width: 100px;
    object-fit: cover;
    min-width: initial;
    max-width: initial;
  }
  .ui.tiny.image.boardImage {
    width: 100px !important;
  }
  .ui.grid > .row > .column {
    padding-right: 0;
  }
}

.BoardDirectory {
  .ui.items > .item:last-child {
    margin-bottom: 14px;
  }
  .boardImage.ui.image img {
    height: 130px;
    width: 100px;
    object-fit: cover;
    min-width: initial;
    max-width: initial;
  }
  .ui.tiny.image.boardImage {
    width: 100px !important;
  }
  .ui.grid > .row > .column {
    padding-right: 0;
  }
}

.videoContainer {
  background: none !important;
  border: none !important;
  box-shadow: none !important;

  & > * {
    background: none !important;
    border: none !important;
  }
}

p {
  font-size: 1.2em;
  line-height: 1.8em;
}

.video {
  img {
    max-height: 230px;
    min-height: 230px;
    max-width: 165px;
    min-width: 165px;
  }
}

.ui.large.image.pdf {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    margin: 1.5em 0;
    max-height: 230px;
    min-height: 230px;
    width: auto !important;
    max-width: 100%;
  }
}

// Phones
@media only screen and (max-device-width: 768px) {
  #pageContent {
    margin-left: 0 !important;
    margin-top: 50px;
    > .ui.container {
      margin-left: 0px;
      .Boxes > div {
        width: 100% !important;
        flex-direction: column;
      }
    }
  }

  .three.wide.column {
    display: none !important;
  }
  .thirteen.wide.column {
    width: 120% !important;
  }
  .ui.column.grid > [class*="thirteen wide"].column,
  .ui.grid > .column.row > [class*="thirteen wide"].column,
  .ui.grid > .row > [class*="thirteen wide"].column,
  .ui.grid > [class*="thirteen wide"].column {
    width: 100% !important;
  }
  .Leadership .ui.grid > .row > .column {
    padding-right: 0;
    width: 100% !important;
  }
  .ui.grid > .row > .column {
    padding-right: 0;
    width: 100% !important;
  }
  .MemberHome .ui.column.grid > [class*="ten wide"].column,
  .ui.grid > .column.row > [class*="ten wide"].column,
  .ui.grid > .row > [class*="ten wide"].column,
  .ui.grid > [class*="ten wide"].column {
    width: 100% !important;
  }
  .MemberHome .ui.column.grid > [class*="six wide"].column,
  .ui.grid > .column.row > [class*="six wide"].column,
  .ui.grid > .row > [class*="six wide"].column,
  .ui.grid > [class*="six wide"].column {
    width: 100% !important;
    display: row;
  }
  .ui.sponsorLogos > .images {
    justify-content: space-evenly;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .Splash {
    #splash {
      height: 20px;
    }
  }
  .ui.menu > .container,
  .ui.menu > .grid {
    justify-content: space-evenly;
    width: 50%;
  }
  .ui.menu .item > .input {
    display: none;
  }
  .ui.mini.image.MainLogo {
    border: none !important;
  }
  .ui.visible.left.overlay.sidebar {
    max-width: 100%;
  }
  .sidebar.menu .ui.item {
    text-align: left;
  }
  .ui.horizontal.divider:after,
  .ui.horizontal.divider:before {
    width: 0px;
  }

  #pageContent {
    margin-left: 0;
    margin-right: 0 !important;
  }

  #MainMenu ~ .ui.fluid.container {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .Box {
    width: auto !important;
    height: auto !important;

    > div {
      height: auto !important;
      max-height: initial !important;
      width: auto !important;
    }
  }

  .ui.huge.action.input {
    margin-top: 40px !important;
    width: 100% !important;
  }

  #splash {
    margin-top: -10px !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100vw !important;
    background-position: 0 0;
    height: 125px;
    margin-bottom: 40px;
  }

  .ui.container#splashToo {
    width: 100vw !important;
    margin-left: -2em !important;
  }

  .ui.huge.action.input {
    font-size: 1rem;
  }

  .ui.container.sponsorLogos {
    margin-bottom: 25px !important;
    max-width: 100vw !important;
  }

  .learningCenter > .ui.three.doubling.cards > .card {
    width: 90%;
  }
  .learningCenter > .ui.vertical.menu {
    width: 100%;
    text-align: left;
  }
  .BecomeASponsor {
    table {
      font-size: 1.2em !important;
      width: 90%;
    }
  }
}

// Tablets
@media only screen and (max-device-width: 768px) {
  .three.wide.column {
    display: none !important;
  }
  .thirteen.wide.column {
    width: 100% !important;
  }
  .ui.column.grid > [class*="thirteen wide"].column,
  .ui.grid > .column.row > [class*="thirteen wide"].column,
  .ui.grid > .row > [class*="thirteen wide"].column,
  .ui.grid > [class*="thirteen wide"].column {
    width: 100% !important;
  }
  .Leadership .ui.grid > .row > .column {
    padding-right: 0;
    width: 50% !important;
  }
  .MemberHome .ui.column.grid > [class*="ten wide"].column,
  .ui.grid > .column.row > [class*="ten wide"].column,
  .ui.grid > .row > [class*="ten wide"].column,
  .ui.grid > [class*="ten wide"].column {
    width: 100% !important;
  }
  .MemberHome .ui.column.grid > [class*="six wide"].column,
  .ui.grid > .column.row > [class*="six wide"].column,
  .ui.grid > .row > [class*="six wide"].column,
  .ui.grid > [class*="six wide"].column {
    width: 100% !important;
  }
  .ui.grid > [class*="four wide"].column,
  .ui.grid > [class*="two wide"].column {
    width: 100% !important;
    text-align: center;
  }
  .ui.grid > [class*="one wide"].column {
    width: 100% !important;
    flex-direction: row !important;
    text-align: center;
    justify-content: space-evenly;
  }
  .ui.menu > .container,
  .ui.menu > .grid {
    width: 40%;
    justify-content: space-evenly;
  }
  .ui.menu .item > .input {
    display: none;
  }
  .ui.mini.image.MainLogo {
    border: none !important;
  }
  .ui.visible.left.overlay.sidebar {
    max-width: 100%;
  }

  #pageContent {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  #MainMenu ~ .ui.fluid.container {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .Box {
    width: auto !important;
    height: auto !important;

    > div {
      height: auto !important;
      max-height: initial !important;
      width: auto !important;
    }
  }

  .Boxes img.more {
    position: absolute;
    right: 5px;
    bottom: 15px;
    padding: 5px;
  }

  .Box_Box__VpISF > div.Box_withImage__3Fs0t {
    min-height: 200px;
    min-width: 300px;
    .ui.huge.action.input {
      margin-top: 40px !important;
      width: 100% !important;
    }
  }

  #splash {
    margin-top: -10px !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100vw !important;
    background-position: 0 0;
    height: 125px;
    margin-bottom: 40px;
  }

  #findSpecialist.ui.orange.button {
    margin-top: 10px !important;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3) !important;
  }

  .ui.huge.action.input {
    font-size: 1rem;
  }

  .sponsorLogos {
    margin-bottom: 15px !important;
  }
}

.pushable {
  height: auto !important;
}

.pusher {
  overflow: visible !important;
}

.ui.vertical.menu .dropdown.item .menu {
  left: 0;
}

#closeSidebar {
  display: inline-block;
  align-self: flex-end;
  margin: 10px;
  cursor: pointer;
}

#pageContent {
  .ui.menu .item {
    color: black;
  }
}

@media print {
  header nav footer {
    display: none;
  }
}

fieldset {
  border: 1px solid #ccc;
  padding: 1rem 1.5rem;
  margin-bottom: 1.5rem;

  legend {
    font-size: 1.2rem;
    margin: 5px;
  }
}

#adminFilters {
  & > * {
    margin-right: 5px;
    padding-right: 10px;
    padding-left: 5px;

    &:not(:last-child) {
      border-right: 1px solid #ccc;
    }
  }
}

.rsm-svg {
  margin-top: -50px;
}

figure {
  margin: 0;

  img {
    max-width: 100%;
  }
}

.MainLogo,
.ui.mini.image.MainLogo,
.ui.mini.images .image.MainLogo,
.ui.mini.images img.MainLogo,
.ui.mini.images svg.MainLogo {
  width: 150px;
  height: auto;
  font-size: 0.78571429rem;
  position: absolute;
  top: 0;
  left: 2%;
  border: 2px solid #429997;
}

@media only screen and (max-width: 765px) {
  .MainLogo,
  .ui.mini.image.MainLogo,
  .ui.mini.images .image.MainLogo,
  .ui.mini.images img.MainLogo,
  .ui.mini.images svg.MainLogo {
    width: 125px !important;
    height: 35px !important;
    margin-top: 3px;
  }
  #findSpecialist.ui.orange.button {
    font-size: 1.14285714rem !important;
    margin-top: 100px !important;
  }
  .ui.grid {
    max-width: initial !important;
  }
  .footer {
    #footerSocial {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    #footerAddress {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}

.sun-editor-editable[contenteditable="false"] {
  cursor: not-allowed;
}
