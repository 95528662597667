.container {
  position: fixed;
  bottom: 15px;
  left: 15px;
  z-index: 9999999;
  width: auto !important;

  > button {
    width: auto !important;
  }

  > div {
    background-color: #fff;
    border: 1px solid #efefef;
    border-radius: 5px;
    margin: 10px;
    padding: 15px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
}

.open {
  width: 400px !important;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23) !important;
  &:hover {
    box-shadow: none !important;
  }
}
