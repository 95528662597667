@media print {
  #pageContent{
    .three.column.wide {
      display:none!important;
    }
  }

  #MainMenu {
    display:none!important;
  }

  .ui.three.steps {
    display:none!important;
  }

  .ui.four.steps {
    display:none!important;
  }

  .ui.five.steps {
    display:none!important;
  }

  .ui.form {
    width: 100%!important;
  }

  button {
    display:none!important;
  }

  #disclaimer {
    display:none!important;
  }

  .footer {
    display: none!important;
  }

  // .Print-FullWidth, .ui.grid > .row > [class*="six wide"].column.Print-FullWidth {
  //   width: 100% !important;
  //   position: initial;
  //   transition: none;
  //   filter: none;
  // }

  .ui.tiny.images {

  }

  [class^="Feedback"] {
    display: none !important;
  }

  .Therapists .ui.grid > .row > [class*="ten wide"].column {
    width: 100% !important;
    clear: both;
    display: block !important;
  }

  .ui.segment { border: none; }

  .NoPrint {
    display: none !important;
  }

  #votes {
    .ui.segment {
      padding-right: 25px!important;
    }
  }

  .SponsorPrintOnly {
    display: block !important;
  }
}
