.SponsorLogo {
  min-width: 125px;
  min-height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  align-self: center;
  background-position: center center;
  filter: grayscale(0) opacity(0.9);
  transition: filter 0.5s;
  outline: none;
}

.activeSponsor {
  padding: 15px 35px;
  background-color: #efefef;
  border-radius: 5px;
  border: 1px solid #ccc;
  outline: none;
}

.SponsorLogo:hover {
  filter: grayscale(0);
  cursor: pointer;
}

.SponsorGroup {
  min-height: 125px;
  flex-wrap: wrap;
}

@media (min-width: 453px) and (max-width: 1820px) {
  .SponsorGroup {
    margin-bottom: 45px !important;
  }
}
