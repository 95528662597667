.SiteAlert {
  width: 100vw;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #573a08;
  background-color: #fffaf3;

  strong {
    margin-right: 5px;
  }
}
